import ContentPage from "../components/ContentPage";
import SocialPage from "../components/SocialPage";
import MixedPage from "../views/MixedPage";
import artists from "./artists";
import stlw from "./stlw";
import team from "./team";
import stalowproductions from "./stalowproductions";
import artistsstlw from "./artistsstlw";
import stalowfest from "./stalowfest";


export default {
  home: {
    path: "/",
    name: "home",
    component: MixedPage,
    props: {
      components: [
        {
          component: "CountDown",
          props: {
            title:
              "8ª edición de STALOW FEST 26/10/2024",
            date: "2024-10-26 12:00:00"
          }
        },
        {
          component: "SocialPage",
          props: {
            sections: stlw
          }
        },
       
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#000",
            image_background: "",
            video: "",
            image: "/static/portada 24.png",
            title: "INSCRÍBETE AQUÍ para poder participar en todos los eventos del STLW TOUR 2024",
            text:
              "No te pierdas el primer evento del #STLWTour24 en la sala EL GRIS de Manresa con la primera jornada de la #STLWLeague a cargo de SDG",
            textPosition: "left",
            buttonLink: "https://mailchi.mp/b9ff97216ca9/oooix2mddo",
            buttonText: "Inscripciones STLW Tour 24",
          }
        },
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#FFF",
            image_background: "/static/bg.jpg",
            video: "_5Aryi-pfn8",
            // image: '',
            title: "Ésta es la nueva comunidad HipHoppa.",
            text:
              "Propulsa tu carrera y conoce a nuevas amistades dentro del mundo del Hip Hop.",
            textPosition: "left"
          }
        },
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#000",
            image_background: "",
            video: "",
            image: "/static/cartell inicial fet.png",
            title: "CON ÉSTE CALENDARIO podrás estar al corriente de todos los eventos",
            text:
              "¡Comenta en el post que eventos estás esperando & stay tunned para nuevas actualizaciones!",
            textPosition: "left",
            buttonLink: "https://www.instagram.com/stalowfest/",
            buttonText: "Post IG Calendario",
          }
        },
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#FFF",
            image_background: "/static/bg.jpg",
            video: "FXqdMxwkMI4",
            image: "/static/logo-spanish-beatbox-battle-petit.png",
            title: "¿Aún no has visto LA SERIE?",
            text:
              "Una serie basado en hechos hiphoppas.",
            textPosition: "left",
            buttonLink: "https://mailchi.mp/eb8abdb60176/the-stalow-project",
            buttonText: "¡Haz click para verla YA!",
          }
        },
        
       
        
        
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#000",
            // image_background: '/static/bg.jpg',
            video:
              "https://www.youtube.com/watch?v=sl7o5SJOhXs&list=PL1yJdjAZ5dZq3Mne9eQYuKAB19sYffFGX&index=2",
            image: "/static/logo-spanish-beatbox-battle-petit.png",
            title: "Musica de artistas con Stalow.",
            text: "¡Escucha lo que gente como tú ha creado con nosotros!",
            textPosition: "left",
            buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZq3Mne9eQYuKAB19sYffFGX",
            buttonText: "Lista Musical Stalow Productions",
          }
        },
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#FFF",
            image_background: "/static/bg.jpg",
            video: "0v09SY1D_4Q",
            // image: '',
            title: "Stalow Fest ",
            text:
              "Conéctate al Hip Hop con nuestro festival de cultura urbana",
            textPosition: "left"
          }
        },
        {
          component: "ContentSection",
          props: {
            primary: true,
            fontColor: "#000",
            // image_background: '/static/bg.jpg',
            video:
              "",
            image: "/static/academy1.jpg",
            title: "Academia de Skate",
            text: "¡Aprende a patinar todas las tardes entre semana con Stalow Academy!",
            textPosition: "left",
            buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZq3Mne9eQYuKAB19sYffFGX",
            buttonText: "Inscripciones skate extraescolar",
          }
        }
      ] 
    }
  }, 
  menu: {
    
    fest: {
      stalowfest: {
        path: "/stalowfest",
        name: "Stalow Fest",
        component: MixedPage,
      props: {
        components: [
         
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              image: "/static/stlwbatalladegallos.jpg",
              
              video:"0v09SY1D_4Q",
              title: "STALOW FEST",
              text:
                "Conéctate al Hip Hop con nuestro festival de cultura urbana",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/eb8abdb60176/the-stalow-project",
              buttonText: "¡Haz click para verla YA!",
            }
          },
          {
            component: "SocialPage",
            props: {
              sections: stalowfest
            }
          },
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#000",
              video: "-MzAbx67ttc",
              image: "/static/logo.png",
              title: "FREESTYLE",
              text:
                "¡Conéctate al freestyle con la final de la STLW League!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZpOee7a37Yhq9D3LgdZk2Gf",
              buttonText: "Ver final  STLW League 2022",
            }
          },
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "W58UwHRLnq8",
              image: "/static/logo.png",
              title: "SKATEBOARDING",
              text:
                "¡Conéctate al skate con la Skate Party de Stalow Fest!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZpkdjLjMECoP6ewPyuk_jxE",
              buttonText: "Ver videos Skate Party 2022",
            }
          },
          
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#000",
              video: "WMs_F2_J57w",
              image: "/static/logo.png",
              title: "BEATBOX",
              text:
                "¡Conéctate al Beatbox con el campeonato de Cataluña BEATCAT!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZr-MpjDnAM6AF9jkoehWOIa",
              buttonText: "Ver videos Spanish Beatbox Battle 2022",
            }
          },
          
          
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "tryw4WR-3P4",
              image: "/static/logo.png",
              title: "BREAKDANCE",
              text:
                "¡Conéctate al breakdance con el campeonato Break Draft!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZr-MpjDnAM6AF9jkoehWOIa",
              buttonText: "Ver videos Beatcat 2022",
            }
          },

          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#000",
              video: "GokDOnzSoas",
              image: "/static/logo.png",
              title: "GRAFFITI",
              text:
                "¡Conéctate al graffiti con la exhibición organizada por Murs Plàstics y Stalow!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrnR5rtN76Mpt_OSScXwujo",
              buttonText: "Ver EXHIBICIÓN",
            }
          },
          {
            component: "ContentSection",
            props: {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "r7VaFldklXA",
              image: "/static/logo.png",
              title: "CONCIERTOS",
              text:
                "¡Conéctate a la música urbana con los conciertos de Stalow Fest!",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZpfy5G6-l8COOthjT5ErJvL",
              buttonText: "Ver directos Stalow Fest",
            }
          },

            
            {
              component: "SocialPage",
              props: {
                sections: artistsstlw
              }
            },
          ],
        } 
      },
    },
    productions: {
     
      "servicios": {
        path: "/productions/servicios",
        name: "servicios",
        component: MixedPage,
        props: {
          components: [
           
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                video: "FXqdMxwkMI4",
                image: "/static/logo-spanish-beatbox-battle-petit.png",
                title: "STALOW FEST 2023",
                text:
                  "Conéctate al HIP-HOP en el festival de cultura urbana de la Asociación Stalow",
                textPosition: "left",
                buttonLink: "https://mailchi.mp/eb8abdb60176/the-stalow-project",
                buttonText: "¡Haz click para verla YA!",
              }
            },
            {
              component: "SocialPage",
              props: {
                sections: stalowproductions,
              }
            },
            {
              primary: true,
              fontColor: "#000",
              // image_background: '/static/bg.jpg',
              video:
                "https://www.youtube.com/watch?v=sl7o5SJOhXs&list=PL1yJdjAZ5dZq3Mne9eQYuKAB19sYffFGX&index=2",
              // image: '/static/logo.png',
              title: "Musica de artistas con Stalow Productions",
              text: "Escucha lo que gente como tú ha creado con nosotros.",
              textPosition: "left",
              buttonLink: "/productions/join-us",
              buttonText: "Lista Musical Stalow Productions"
            },

            {
              primary: false,
              fontColor: "#000",
              video: "fZbuRTbW-c8",
              image: "/static/logo.png",
              title: "STLW Skate Sessions ",
              text:
                "También conocemos los mejores ángulos y las vistas más épicas para tus tricks.",
              textPosition: "left",
              buttonLink:
                "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrnCE6-z68yPJ5jbvL898Gv",
              buttonText: "veure més"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "",
              image: "/static/stalow-starter-pack-TITLE.png",
              title: "Conecta con la comunidad HipHoppa!",
              text: "Te regalaremos herramientas para emprender en el mundo del HIP-HOP",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!"
            }
          ]
        }
      },
      "trabajos": {
        path: "/productions/trabajos",
        name: "Trabajos",
        component: ContentPage,
        props: {
          sections: [
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/logostalowproductions.png",
              title: "IMMORTALIZAR LA CULTURA URBANA",
              text:
                "Éste será nuestro legado audiovisual.",
              textPosition: "left"
            },
            {
              primary: true,
              fontColor: "#000",
              // image_background: '/static/bg.jpg',
              video:
                "https://www.youtube.com/watch?v=sl7o5SJOhXs&list=PL1yJdjAZ5dZq3Mne9eQYuKAB19sYffFGX&index=2",
              // image: '/static/logo.png',
              title: "Artistas con Stalow Productions",
              text: "Escucha lo que gente como tú ha creado con nosotros.",
              textPosition: "left",
              buttonLink: "/productions/join-us",
              buttonText: "Lista Musical Stalow Productions"
            },

            {
              primary: false,
              fontColor: "#000",
              video: "fZbuRTbW-c8",
              image: "/static/logo.png",
              title: "Skaters con Stalow Productions",
              text:
                "También conocemos los mejores ángulos y las vistas más épicas para tus tricks.",
              textPosition: "left",
              buttonLink:
                "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrnCE6-z68yPJ5jbvL898Gv",
              buttonText: "veure més"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "",
              image: "/static/stalow-starter-pack-TITLE.png",
              title: "Conecta con la comunidad HipHoppa!",
              text: "Te regalaremos herramientas para emprender en el mundo del HIP-HOP",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!"
            }
          ]
        }
      },
      "starter pack": {
        path: "/productions/starter-pack",
        name: "Starter Pack",
        component: ContentPage,
        props: {
          sections: [
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "",
              title: "STLW STARTER PACK",
              text:
                "Te ofrecemos herramientas y una comunidad con la que MEDRAR cómo HipHoppa",
              textPosition: "left"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "_CSJcFigBqg",
              image: "/static/Mock up STLW starter pack v2.png",
              title: "Descubre las nuevas versiones del STARTER PACK",
              text:
                "Turoriales, Drumkits y trucos para emprender como hiphoppa",
              textPosition: "right",
              buttonLink:
                "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Quiero el STLW Starter Pack!"
            },

            

            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "",
              image: "/static/stalow-starter-pack-TITLE.png",
              title: "Conecta con la comunidad HipHoppa!",
              text: "Te regalaremos herramientas para emprender en el mundo del HIP-HOP",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "",
              image: "/static/Mock up STLW starter pack v2.png",
              title: "Descubre las nuevas versiones del STARTER PACK",
              text:
                "Turoriales, Drumkits y trucos para emprender como hiphoppa",
              textPosition: "right",
              buttonLink:
                "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Quiero el STLW Starter Pack!"
            }
          ]
        }
      }
    },
    academy: {
      // agenda: {
      //   path: "/academy/agenda",
      //   name: "Agenda",
      //   component: ContentPage
      // },
      Skate: {
        path: "/academy/skate",
        name: "skate",
        component: ContentPage,
        props: {
          sections: [ 
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "Skate Extraescolar escoles 2021 - 2022 mur.png",
              title: "CLASSES EXTRAESCOLARES DE SKATE",
              text:
                "Todas las tardes de lunes a jueves en el skatepark de Manresa. ",
              textPosition: "left"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "K4b3CMhKKv4",
              image: "/static/logo.png",
              title:
                "¡Aprender SKATE nunca fue TAN FÁCIL!",
              text:
                "¡Te sorprenderá lo rápido que aprenderás con nosotros!",
              textPosition: "right",
              buttonLink: "https://mailchi.mp/ebadcbe153d9/skate-academy",
              buttonText: "¡Quiero saber más!"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "",
              image: "/static/stalow-academy.jpg",
              title: "Aprender skate implica aprender ciertos valores.",
              text: "Pasión, motivación, disciplina y control.",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/ebadcbe153d9/skate-academy",
              buttonText: "¡Haz click para reservar una clase gratuita!"
            },
            {
              primary: true,
              fontColor: "#000",
              // video: "K4b3CMhKKv4",
              image: "/static/stalow-academy-title.png",
              title:
                "Aprovecha el precio inigualable de las clases en grupo para iniciarte.",
              text:
                "Y perfecciona tu técnica con las clases particulares.",
              textPosition: "right",
              buttonLink: "https://mailchi.mp/ebadcbe153d9/skate-academy",
              buttonText: "¡Quiero saber más!"
            },
            
          ]
        }
      },
      Music: {
        path: "/academy/music",
        name: "music",
        component: ContentPage,
        props: {
          sections: [ 
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/stalow-productions.jpg",
              title: "COMPONER, GRABAR, PRODUCIR Y MEZCLAR MúSICA HIP-HOP",
              text:
                "Aprende con nosotros a manejar las artes creativas.",
              textPosition: "left"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "AAdxUp8aJew",
              title:
                "De Rookie a Pro Boombap con éste método de Raro Beats",
              text:
                "¡Te sorprenderá lo rápido que aprenderás!",
              textPosition: "right",
              buttonLink: "https://youtube.com/playlist?list=PL1yJdjAZ5dZo5oryMfCWACk8doAZ2QprV",
              buttonText: "Ver Curso Boombap 0 a 100"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "r6ETTQprEpk",
              image: "/static/stalow-academy.jpg",
              title: "Masterclass Rarobeats Metodo Stalow Producer",
              text: "Pasión, motivación, disciplina y control.",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrinxYUuV-AH1H5k_px38Vl",
              buttonText: "¡Haz click para acceder a la masterclass gratuita!"
            },
            {
              primary: true,
              fontColor: "#000",
              // video: "K4b3CMhKKv4",
              image: "/static/stalow-academy-title.png",
              title:
                "Aprovecha el precio inigualable de las clases en grupo para iniciarte.",
              text:
                "Y perfecciona tu técnica con las clases particulares.",
              textPosition: "right",
              buttonLink: "https://mailchi.mp/ebadcbe153d9/skate-academy",
              buttonText: "¡Quiero saber más!"
            },
            
          ]
        }
      },
      Video: {
        path: "/academy/video",
        name: "video",
        component: ContentPage,
        props: {
          sections: [ 
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/stalow-productions.jpg",
              title: "APRENDE A CREAR CONTENIDOS INOLVIDABLES",
              text:
                "Especializados en videoclips, vídeos de skate y eventos",
              textPosition: "left"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "3zk4RyaTeT0",
              title:
                "Así editamos los vídeos de nuestros eventos",
              text:
                "Para los que quieren immortalizar sus momentos.",
              textPosition: "right",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZpo5Fk0fVBz_490W3-okPHC",
              buttonText: "Editando batallas con Ferrics"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "r6ETTQprEpk",
              image: "/static/stalow-academy.jpg",
              title: "¿Quieres aprender a hacer videoclips?",
              text: "Aquí te enseñamos nuesto mejor proceso creativo",
              textPosition: "left",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrinxYUuV-AH1H5k_px38Vl",
              buttonText: "¡Haz click para acceder a la masterclass de videoclips gratuita!"
            },
            {
              primary: true,
              fontColor: "#000",
              video: "3zk4RyaTeT0",
              title:
                "Si tu destino es ser filmer de skate, somos tu academia",
              text:
                "Aprende a grabar mientras los alumnos aprenden a patinar",
              textPosition: "right",
              buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZpo5Fk0fVBz_490W3-okPHC",
              buttonText: "Ver masterclass skate filmer"
            },
                        
          ]
        }
      }
    },

    tour: {
      "STALOW Tour": {
        path: "/tour/stalowtour",
        name: "STLW Tour",
        component: ContentPage,
        props: {
          sections: [
            
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/portada 24.png",
              title: "INSCRÍBETE AQUÍ para poder participar en todos los eventos del STLW TOUR 2024",
              text:
                "No te pierdas el primer evento del #STLWTour24 en la sala EL GRIS de Manresa con la primera jornada de la #STLWLeague a cargo de SDG",
              textPosition: "left",
              buttonLink:"https://mailchi.mp/b9ff97216ca9/oooix2mddo",
              buttonText: "Inscripciones STLW Tour 24"
            },
            {
              primary: true,
              fontColor: "#000",
              // video: 'qdlv1udS0MA',
              image: "/static/cartell inicial fet.png",
              title: "CON ÉSTE CALENDARIO podrás estar al corriente de todos los eventos",
              text:
                "¡Comenta en el post que eventos estás esperando & stay tunned para nuevas actualizaciones!",
              textPosition: "right",
              buttonLink:
                "",
              buttonText: "Haz clic aquí para apuntarte a un evento"
            },
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/afus.",
              title: "STLW TOUR 2023",
              text:
                "Batallas de freestyle, beatbox, competiciones de skate, exhibiciones de grafiti conciertos y mucho más",
              textPosition: "left"
            },
            {
              primary: false,
              fontColor: "#000",
              // image_background: '/static/bg.jpg',
              video: "https://www.youtube.com/watch?v=e65q8KxUFKk&list=PL1yJdjAZ5dZqtO_7KvvJak4xbljZvB5Jj&index=1",
              image: "/static/logo.png",
              title: "Vídeos STLW Tour 2023",
              text: "Revive los eventos del STLW Tour 23",
              textPosition: "right",
              buttonLink:
                "https://studio.youtube.com/playlist/PL1yJdjAZ5dZqtO_7KvvJak4xbljZvB5Jj/edit",
              buttonText: "Ver lista STLW Tour 23"
            },
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/tour2.jpg",
              title: "STLW TOUR 21/22",
              text:
                "Batallas de freestyle, beatbox, competiciones de skate, exhibiciones de grafiti conciertos y mucho más",
              textPosition: "left"
            },
            {
              primary: false,
              fontColor: "#000",
              // image_background: '/static/bg.jpg',
              video: "0v09SY1D_4Q",
              image: "/static/logo.png",
              title: "Vídeos STLW Tour 21/22",
              text: "Revive los eventos del STLW Tour 21/22",
              textPosition: "right",
              buttonLink:
                "https://youtube.com/playlist?list=PL1yJdjAZ5dZqj4nEaQPxxla_zXiPRemwT",
              buttonText: "Ver vídeos STLW Tour 21/22"
            },
            {
              primary: true,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              // video: 'Iy-dJwHVX84',
              image: "/static/tour1.jpg",
              title: "STLW TOUR 2020",
              text:
                "Batallas de freestyle, conciertos y mucho aprendizaje",
              textPosition: "left"
            },
            {
              primary: false,
              fontColor: "#000",
              // image_background: '/static/bg.jpg',
              video: "Pm_7Jx4nHd8",
              // image: '/static/logo.png',
              title: "",
              text: "¡Revive ahora también cada evento del STLW Tour 2020!",
              textPosition: "right",
              buttonLink:
                "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZotDJsYneA_CB61jbRqT0e6",
              buttonText: "Ver Vídeos STLW Tour 2020"
            },
            {
              primary: false,
              fontColor: "#FFF",
              image_background: "/static/bg.jpg",
              video: "",
              image: "/static/stalow-starter-pack-TITLE.png",
              title: "Conecta con la comunidad HipHoppa!",
              text: "Te regalaremos herramientas para emprender en el mundo del HIP-HOP",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!"
            }
          ]
        }
      },
    },
    "Project": {
      "The STALOW Project": {
        path: "/project/temporada1",
        name: "1ª Temporada (2019)",
        component: MixedPage,
    props: {
      components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                image: "/static/the-stalow-project.png",
                video: "FXqdMxwkMI4",
                title: "UNA SERIE BASADA EN HECHOS HIPHOPPAS",
                text:
                  "Descubre el auténtico significado que el Hip-Hop puede tener para ti.",
                  textPosition: "left",
                  buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZrJpC7HEqBRZnM4bHW5YsHt",
                  buttonText: "Ver - The STALOW Project -",
              }
            },
            {
              component: "CountDown",
              props: {
                title:
                  "NO te pierdas el ESTRENO de la nueva temporada ",
                date: "2023-06-30 16:20:00"
              }
            },
            

            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "CONECTA CON PERSONAS HIPHOPPAS",
                text:
                  'Únete a la comunidad de protagonistas que ya han immortalizado su legado hiphoppa en ésta serie, y quien sabe hasta dónde podrás llegar.',
                textPosition: "MID"
              }
            },
            {
              component: "SocialPage",
              props: {
                sections: team
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                image: "/static/stalow-starter-pack-TITLE.png",
                title: "¡Conecta con la comunidad HipHoppa!",
                text: "Te regalamos recursos para producir y emprender",
                textPosition: "left",
                buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
                buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!"
              }
            }
          ]
        }
      }
    },
    "CLUB": {
      "STALOW Club": {
        path: "/stalowclub",
        name: "Stalow Club",
        component: MixedPage,
    props: {
      components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                image: "/static/the-stalow-project.png",
                video: "FXqdMxwkMI4",
                title: "WELCOME to the STALOW CLUB",
                text:
                  "Un nuevo espacio para hiphoppas donde quedar para cantar, grabar o compartir momentos tomando algo con tu gente.",
                  textPosition: "left",
                  buttonLink: "https://mailchi.mp/c9b8b804b2b6/stalow-club-member",
                  buttonText: "¡Hazte STLW Member y nos vemos en el local!",
              }
            },
            {
              component: "CountDown",
              props: {
                title:
                  "NO te pierdas la GRAN INAUGURACIÓN del local ",
                date: "2023-06-30 16:20:00"
              }
            },
            

            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "LLEGÓ EL ESPACIO Y TIEMPO DONDE DESARROLLAR TU POTENCIAL HIPHOPPA",
                text:
                  'Aprovecha el momento para propulsar tu carrera y conectar con la comunidad',
                textPosition: "MID"
              }
            },
          
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                image: "/static/stalow-starter-pack-TITLE.png",
                title: "ESTUDIO DE GRAVACIÓN",
                text: "Una vez dentro del club, tendrás el nuevo estudio de Stalow Productions a tu disposición con precios vip",
                textPosition: "left",
                buttonLink: "https://mailchi.mp/7c4d3cdd62d5/lista-de-grabacin-estudio-web",
                buttonText: "¡HAZ CLICK PARA MÁS INFORMACIÓN SOBRE TU PRIMERA SESIÓN AQUÍ!"
              }
            }
          ]
        }
      }
    },
    blog: {
      "Presentació serie The STALOW Project ": {
        path: "/blog/thestalowproject",
        name: "Article 1 - Presentació projecte STALOW",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                // video: "_5Aryi-pfn8",
                // image: '/static/stalow-starter-pack-TITLE.png',
                title: "Joves Manresans forçats a innovar converteixen el seu festival en una serie.",
                text:
                  "El projecte STALOW, a més d'una serie-reality, pretén ser una obra d’integració social, de llibertat d’expressió i de supervivència a través de la cultura urbana. ",
                textPosition: "middle",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                video: "R4OPM34BdJM",
                // image: '/static/stalow-starter-pack-TITLE.png',
                title:
                  "El projecte Stalow es va iniciar fa 6 anys amb la primera edició del festival de cultura urbana “Stalow Fest”.",
                text:
                  " El festival de cultura urbana Stalow Fest s’ha repetit durant 5 anys fins que la pandèmia ens ha fet parar, aconseguint la col·laboració de diferents col·lectius del mon del hip hop com l’entitat dedicada a la pintura “Murs Plàstics” i el col·lectiu que hi ha redera la “Spanish Beatbox Battle”, el campionat d’Espanya de Beatbox, que va celebrar la seva final del 2019 al Stalow Fest de Manresa.",
                textPosition: "right",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#000",
                // image_background: '/static/stalow-tour-2020-lfd.jpg',
                video: "",
                image: "/static/stalow-tour-2020-lfd.jpg",
                title:
                  "Durant aquest any de pandèmia, els esdeveniments massius com festivals hem sigut els més afectats.",
                text:
                  "La por, la responsabilitat social i les mesures legislatives son tres grans barreres que s’interposen entre el públic i el sector dels grans espectacles. Enfront d’aquesta situació, vam decidir adaptar el format dels nostres esdeveniments, i durant aquest 2020, hem organitzat esdeveniments de petit format amb totes les mesures de prevenció exigides pels ajuntaments, i donant lloc així a la creació del primer STALOW TOUR. Les ciutats que van donar pas al STALOW TOUR 2020 van ser Collbató, Sampedor, Manresa, El Xup, Sant Fruitós de Bages i Balsareny, tot i haver cancel·lat per la pandèmia la gran majoria d’esdeveniments restants previstos. Molts d’aquests petits actes són només per a participants i en algunes ocasions es pot habilitar una petita part destinada a un petit públic, un entorn que no te res a veure amb l’espai multicultural que la gent podia trobar al clàssic Stalow Fest de Manresa.",
                textPosition: "left",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'FXqdMxwkMI4',
                image: "/static/the-stalow-project.jpg",
                title:
                  "Aleshores vam iniciar el projecte audiovisual més ambiciós mai produit per Stalow Productions, LA SERIE. ",
                text:
                  "L'objectiu de la primera temporada d'aquesta serie es explicar tot el que va passar durant l’última edició del Stalow Fest a Manresa, perquè el públic que en circumstàncies normals hauria vingut a disfrutar del rap, el graffiti, la dansa, el skate, el beatbox i la cultura urbana en general aquest 2020 al Congost de Manresa, ho pugui fer desde casa seva a través de qualsevol pantalla.",
                textPosition: "right",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                video: "FXqdMxwkMI4",
                // image: '/static/stalow-starter-pack-TITLE.png',
                title:
                  "Aquest estiu estrenarem oficialment la 1ª temporada, però ja es pot veure en exclusiva",
                text:
                  "Aquesta serie permet reviure el que de moment ha sigut el millor Stalow Fest, que es va celebrar el 29 de juny del 2019.",
                textPosition: "left",
                buttonLink: "https://mailchi.mp/eb8abdb60176/the-stalow-project",
                buttonText: "¡Fes click per veurela JA!"
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'FXqdMxwkMI4',
                image: "/static/stich-tiger.jpg",
                title:
                  "Al final, el que pretenem es motivar a una generació amb la nostra història.",
                text:
                  "Pretenem mostra la nostra experiència en organització d’esdeveniments i producció musical a través d’aquesta sèrie, i oferir les eines necessàries perquè altres persones puguin transformar aquesta motivació en accions que donin valor a la comunitat. ",
                textPosition: "right",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                video: "ByKJIUnY96Q",
                // image: '/static/stalow-starter-pack-TITLE.png',
                title:
                  "Tots els esdeveniments i produccions musicals que hem realitzat durant aquest 2020, s’estan ara mateix processant per produir la 2ª temporada de la sèrie.",
                text:
                  "Esperem que aquesta primera temporada permeti perpetuar un llegat de temporades que esperem que ens acompanyin durant molts anys, en la feina de divulgació d’aquest projecte nascut a Manresa, i vivint la evolució creixent de valor que un sol grup de joves es capaç de generar en pro del Hip Hop.",
                textPosition: "left",
                buttonLink: "",
                buttonText: ""
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // image: '/static/the-stalow-project.png',
                video: "wxKbyWB0dGM",
                title: "El nostre GRAN sacrifici pel HIP HOP",
                text:
                  "Vam necessitar un présteg de 2.500€ després de l'últim Stalow Fest...",
                textPosition: "right",
                buttonLink:
                  "https://www.migranodearena.org/reto/-the-stalow-project---la-serie-",
                buttonText: "Ens vols ajudar a tornar el présteg?"
              }
            },

            {
              component: "ContentSection",
              props: {
                primary: false,
                fontColor: "#000",
                // image_background: '/static/bg.jpg',
                image: "/static/the-stalow-project-title.png",
                // video: 'FXqdMxwkMI4',
                title: "The STALOW Project - LA SERIE",
                text: "Descobreix la nostra història avans de l'estrena.",
                textPosition: "left",
                buttonLink:
                  "https://mailchi.mp/eb8abdb60176/the-stalow-project",
                buttonText: 'Veure la serie "The STALOW Project" en exclusiva'
              }
            },

            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'ByKJIUnY96Q',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "Coneix als protagonistes de LA SERIE",
                text: "Aquesta serie es real, i tú també pots formar-ne part! ",
                textPosition: "left"
              }
            },
            {
              component: "SocialPage",
              props: {
                sections: team
              }
            },
            {
              primary: false,
              fontColor: "#000",
              image_background: "",
              video: "",
              image: "/static/stalow-starter-pack-TITLE.png",
              title: "Conecta con la comunidad HipHoppa!",
              text: "Te regalaremos herramientas para emprender en el mundo del HIP-HOP",
              textPosition: "left",
              buttonLink: "https://mailchi.mp/acd4fb48e50c/stalow-starter-pack",
              buttonText: "¡Consigue el STALOW STARTER PACK GRATIS AQUÍ!",
            }
          ]
        }
      }
    },

    team: {
      
      "artistas stlw": {
        path: "/team/artistas",
        name: "artistas",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "¡Hola Artista! ¿Quieres actuar?",
                text:
                  'Únete a lista de artistas stalow y te encontraremos eventos donde actuar.',
                textPosition: "left"
              }
            },
            {
              component: "SocialPage",
              props: {
                sections: artists
              }
            }
          ]
        }
      },
      staff: {
        path: "/team/staff",
        name: "staff",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "¿Quieres formar parte del EQUIPO?",
                text:
                  'Únete a las personas que han protagonizado la primera temporada de la serie.',
                textPosition: "left"
              }
            },
            {
              component: "SocialPage",
              props: {
                sections: team
              }
            }
          ]
        }
      }
    },
    streams: {
      youtube: {
        path: "/streams/youtube",
        name: "Youtube",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "¡Estamos en directo!",
                text:
                  'Observa como trabajamos en tus proyectos.',
                textPosition: "left"
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: "/static/bg.jpg",
                video: 'ILzLiN4ZgrA',
                image: '/static/raro-streams.jpg',
                title: "¡No te pierdas NADA!",
                text:
                  'Recupera todos los streams y no te pierdas ningun conocimiento.',
                textPosition: "left",
                buttonLink: "https://www.youtube.com/playlist?list=PL1yJdjAZ5dZomrNjHKCIG9NVRfOc4cFLP",
                buttonText: "Ver lista completa de STREAMS",
              }
            },
            
          
          ]
        }
      },
      twitch: {
        path: "/streams/twitch",
        name: "Twitch",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "¡Estamos en directo!",
                text:
                  'Observa como trabajamos en tus proyectos.',
                textPosition: "left"
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: "/static/bg.jpg",
                // video: 'ILzLiN4ZgrA',
                image: '/static/twitch.png',
                title: "¡También estamos en Twitch!",
                text:
                  'Conéctate al Hip-Hop en directo',
                textPosition: "left",
                buttonLink: "https://www.twitch.tv/stalowtv",
                buttonText: "Entrar en STALOW TV",
              }
            },
            
      
          ]
        }
      }
    },
    clothing: {
      camis: {
        path: "/clothing/camis",
        name: "camis",
        component: MixedPage,
        props: {
          components: [
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#FFF",
                image_background: "/static/bg.jpg",
                // video: 'Iy-dJwHVX84',
                // image: '/static/logo-spanish-beatbox-battle-petit.png',
                title: "En producción",
                text:
                  'Ropa Hiphoppa',
                textPosition: "left"
              }
            },
            {
              component: "ContentSection",
              props: {
                primary: true,
                fontColor: "#000",
                // image_background: "/static/bg.jpg",
                video: '',
                image: '/static/logo stalow clothing.png',
                title: "La moda urbana también es una rama del Hip-Hop",
                text:
                  'Viste representando aquello que te hace brillar',
                textPosition: "left",
                buttonLink: "",
                buttonText: "Ver tienda STALOW CLOTHING",
              }
            },
          ]
        }
      },
          },
  }
}
